import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, Button, Checkbox } from "@nextui-org/react";
import { useForm, SubmitHandler } from "react-hook-form";

import { createPanelist } from "@/api/index";
import { useAlertContext } from "@/contexts/alertContext";
import { disabledTimer } from "@/utils/helpers";
import Input from "@/components/input/input";
import ExternalHeader from "../header/externalHeader";

interface ISignUpForm {
  email: string;
  terms: boolean;
}

interface SignUpFormProps {
  setEmail: Dispatch<SetStateAction<string>>;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ setEmail }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<ISignUpForm>();
  const [isDisabled, setIsDisabled] = useState(false);
  const { addAlert } = useAlertContext();

  const onSubmit: SubmitHandler<ISignUpForm> = async ({ email }) => {
    disabledTimer(setIsDisabled);

    try {
      await createPanelist(email);
      setEmail(email);
    } catch (e) {
      addAlert({
        message: e?.message,
        type: "error",
        timeout: 5,
      });
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ExternalHeader title="Sign Up" />
      <Input
        label="Email Address"
        type="email"
        placeholder="Username"
        register={register("email", {
          required: "Please enter your email address",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Please enter a valid email address",
          },
        })}
        autoComplete="email"
        errorMessage={errors?.email?.message}
      />
      <Checkbox
        {...register("terms", {
          required: "You must accept the terms and conditions",
        })}
      >
        <div className="inline-flex gap-1">
          <p className="block text-sm">I have read and agree to the</p>
          <Link href="/terms-and-privacy" size="sm">
            terms and conditions
          </Link>
        </div>
      </Checkbox>
      <div className="text-danger text-xs mb-4">{errors?.terms?.message}</div>
      <div className="flex items-center justify-content gap-9">
        <Button type="submit" color="secondary" disabled={isDisabled}>
          Sign Up
        </Button>
        <Link href="/">Already have an account?</Link>
      </div>
    </form>
  );
};

export default SignUpForm;
