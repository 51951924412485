import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { isChrome, isMobile } from "react-device-detect";
import { Button } from "@nextui-org/react";

import { useAppSelector } from "./hooks/store";
import { FocusNavbar } from "./components/navbar/navbar";
import InstallChrome from "./components/install/installChrome";
import InstallMobileApp from "./components/install/installMobileApp";
import Login from "./components/login/login";
import { SessionRoute } from "./types/session";
import NotFound from "./pages/notFound";

const Root = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const panelist = useAppSelector((state) => state.auth.panelist);

  const { pathname } = useLocation();
  const isSessionRoute =
    Object.values(SessionRoute).includes(pathname as SessionRoute) &&
    isLoggedIn;

  const externalOnlyPaths = [
    "/account-admin/sign-up",
    "/account-admin/forgot-password",
    "/account-admin/reset-password",
    "/account-admin/create-password",
  ];
  const globallyAvailablePaths = [
    "/data-deletion-request",
    "/terms-and-privacy",
  ];
  const allExternalPaths = externalOnlyPaths.concat(globallyAvailablePaths);

  useEffect(() => {
    (window as any).userpilot.identify(panelist);
  }, [panelist]);

  const getContent = () => {
    if (!isLoggedIn && !allExternalPaths.includes(pathname)) return <Login />;
    if (isLoggedIn && externalOnlyPaths.includes(pathname)) return <NotFound />;
    if (isSessionRoute && isMobile) return <InstallMobileApp />;

    if (isSessionRoute && !isChrome) return <InstallChrome />;
    return <Outlet />;
  };

  return (
    <div className="h-screen relative w-screen flex flex-col">
      {isLoggedIn ? (
        <FocusNavbar />
      ) : (
        <Button
          className="absolute top-5 right-5 z-50"
          onClick={() => (window as any).userpilot.trigger("survey:nvpw3IPTI9")}
          color="primary"
          variant="light"
        >
          Contact Us
        </Button>
      )}
      <main className="light text-foreground bg-background relative p-8 lg:px-16 flex-1 overflow-y-auto">
        <div
          className={`${isSessionRoute && isChrome ? "" : "flex justify-center"}`}
        >
          <div
            className={`${isSessionRoute && isChrome ? "flex flex-col lg:flex-row gap-10 lg:gap-0" : "w-full md:w-3/4 lg:w-1/2"}`}
          >
            {getContent()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Root;
