import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";

import { INIT_RESOLVE_EVENT } from "@lumen-developer/lumen-common-js/esm/brokers";
import { ValidationResult } from "@/types/rni";
import { useAlertContext } from "@/contexts/alertContext";
import { SessionValidationContext, SessionRoute } from "@/types/session";
import { endWildRequest, submitWildValidationRequest } from "../../../api";

const SessionReturn = () => {
  const {
    broker,
    validationRef,
    validationSessionDetails,
    trackerRef,
    errorHandle,
  } = useOutletContext<SessionValidationContext>();
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();
  const [canClick, setCanClick] = useState(true);

  const validate = async () => {
    setCanClick(false);

    try {
      const div = validationRef.current;
      const trackerDiv = trackerRef.current;
      if (!div || !trackerDiv) {
        const message = "Unable to reference calibration div";
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error("Unable to reference calibration div");
      }

      await broker.init(30000, div, {
        resolveEvent: INIT_RESOLVE_EVENT.PROMISE_ALL,
      });
      broker.loadModel("model", "errorCorrection");
      broker.trackersController._videoView.releaseDivElement();
      const validation: ValidationResult = await broker.validate(div, 50000);
      broker.turnOffCamera();

      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime,
      );

      await endWildRequest(
        validationSessionDetails.accessCode,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.panelistId,
      );
      navigate(SessionRoute.COMPLETE);
    } catch (e) {
      const div = validationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      errorHandle(JSON.stringify(e));
    }
  };

  return (
    <>
      <p className="font-semibold mb-10">Validation</p>
      <p className="mb-10">We need to do an end of session validation.</p>
      <p className="mb-10">
        To validate we will ask you to look at another dot.
      </p>
      <p className="mb-10">
        On the next page you will see a green validation dot. Please follow it
        with your eyes as it moves across the screen.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <Button color="secondary" disabled={!canClick} onClick={validate}>
        Continue
      </Button>
    </>
  );
};

export default SessionReturn;
